import React, { Component } from 'react'
import UserDashboard from './UserDashboard'
import ChatApp from "./Chat/ChatApp"
import { baseUrl } from '../../config'

export class Departments extends Component {

    constructor() {
        super()
        this.email = localStorage.getItem('email')
    }

    async componentDidMount() {

        var axios = require('axios');
        var data = JSON.stringify({ "email": JSON.parse(this.email) });

        var config = {
            method: 'post',
            url: `${baseUrl}/parent/me`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios(config)
            .then(async function (response) {
                await console.log(JSON.stringify(response.data));;
                localStorage.setItem('id', JSON.stringify(response.data.data._id))
                localStorage.setItem('firstname', JSON.stringify(response.data.data.firstName))
                localStorage.setItem('lastname', JSON.stringify(response.data.data.lastName))
                localStorage.setItem('phoneCode', JSON.stringify(response.data.data.phoneCode))
                localStorage.setItem('phoneNo', JSON.stringify(response.data.data.phoneNo))
                localStorage.setItem('email', JSON.stringify(response.data.data.email))
                localStorage.setItem('departments', JSON.stringify(response.data.data.hotelId.department))
                if (JSON.parse(localStorage.getItem('departments')).length == 0) {
                    this.props.history.push("/profileView")
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        if (localStorage.getItem("hotelname") === null) {
            this.props.history.push("/guest-signin")
        }
        else if (JSON.parse(localStorage.getItem('departments')).length == 0) {
            this.props.history.push("/profileView")
        }
    }

    render() {
        return (
            <div>
                {localStorage.getItem('departments') != null ? <UserDashboard /> : null}
                <div className="row ml-3 mr-1 "
                    style={{ position: "relative", zIndex: 1, paddingTop: "80px" }}>
                    <div className="col-md-3" />
                    <div className="col-md-6 ml-5 mt-3 mb-5">
                        {localStorage.getItem('departments') != null ? <ChatApp /> : null}
                    </div>
                    <div className="col-md-3" />
                </div>
            </div>
        )
    }
}

export default Departments
