import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

// import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import guest from "../../Images/guest.png"
import { baseUrl } from '../../config'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                triluxo.com
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

const viewFile = (i) => {
    const currentUserPhonecode = localStorage.getItem('phoneCode')
    const currentUserPhoneno = localStorage.getItem('phoneNo')

    var axios = require('axios');
    var data = JSON.stringify({
        "phoneCode": JSON.parse(currentUserPhonecode),
        "phoneNo": JSON.parse(currentUserPhoneno),
        "fileIndex": i
    });

    var config = {
        method: 'post',
        url: `${baseUrl}/parent/file/open`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios(config)
        .then(async function (response) {
            console.log('response')
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}

function open(url, i) {
    viewFile(i);
    const win = window.open(url, '_blank');
    if (win != null) {
        win.focus();
    }
}

export default function ProfileView() {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [names, setNames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        const currentUserPhonecode = localStorage.getItem('phoneCode')
        const currentUserPhoneno = localStorage.getItem('phoneNo')

        if (localStorage.getItem("phoneNo") === null) {
            this.props.history.push("/guest-signin")
        }
        var thisThat = this;

        var axios = require('axios');
        var data = JSON.stringify({ "phoneCode": JSON.parse(currentUserPhonecode), "phoneNo": JSON.parse(currentUserPhoneno) });

        var config = {
            method: 'post',
            url: `${baseUrl}/parent/myFiles`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(async function (response) {
                console.log('response')
                console.log(response.data.files.files);
                setFiles(response.data.files.files);
                setNames(response.data.files.names);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <CameraIcon className={classes.icon} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Welcome to Triluxo
                    </Typography>
                    {/* <Button variant="contained" color="secondary">
                        Sign out
                    </Button> */}
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            Greetings!
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph>
                            Please find your required files here.
                        </Typography>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {files.map((card, i) => (
                            <Grid item key={i} xs={12} sm={6} md={4}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography>
                                            {names[i]}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button onClick={() => { open(card, i) }} size="small">Download</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    Thank you.
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    Wish you have a great experience!
                </Typography>
                <Copyright />
            </footer>
            {/* End footer */}
        </React.Fragment>
    );
}