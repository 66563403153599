import React, { Component } from 'react'
import './auth.css'
import { baseUrl } from '../../config';

export class AddProfile extends Component {

  constructor(props) {
    super(props);
    this.phoneCode = localStorage.getItem('phoneCode')
    this.phoneNo = localStorage.getItem('phoneNo')
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phonecode: this.phoneCode,
      phoneno: this.phoneNo,
      error: '',
      success: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("phoneNo") === null) {
      this.props.history.push("/guest-signin")
    }
  }

  isValidMobile(phoneno) {
    return /^[6-9]\d{9}$/.test(phoneno);
  }

  validateField(field, value) {
    if (/\s/.test(value)) {
      return <div className="alert alert-danger">Spaces not allowed in {field}!!</div>;
    }
    if (value.length <= 0) {
      return <div className="alert alert-danger"><span className="text-capitalize">{field}</span> is required field.</div>;
    } else {
      if (field == 'phoneno') {
        if (!this.isValidMobile(value))
          return <div className="alert alert-danger">Invalid Phone Number.</div>;
      } else {
        return '';
      }
    }
  }

  handleBlur(event) {
    this.setState({ error: this.validateField(event.target.name, event.target.value) });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    var axios = require('axios');
    var data = JSON.stringify({
      "firstName": this.state.firstname,
      "lastName": this.state.lastname,
      "email": this.state.email,
      "phoneCode": this.state.phonecode,
      "phoneNo": this.state.phoneno
    });

    var config = {
      method: 'post',
      url: `${baseUrl}/parent/addProfile`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    const curr = this
    var acess = ""
    await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        acess = JSON.stringify(response.data.success)
        if (!response.data.success) {
          curr.setState({
            error: curr.errorSubmit()
          })
        }
        if (response.data.success) {
          curr.setState({
            firstname: response.data.info.firstName,
            lastname: response.data.info.lastName,
            email: response.data.info.email,
            phonecode: response.data.info.phoneCode,
            phoneno: response.data.info.phoneNo,
          })
          localStorage.setItem('firstname', JSON.stringify(response.data.info.firstName))
          localStorage.setItem('lastname', JSON.stringify(response.data.info.lastName))
          localStorage.setItem('phoneCode', JSON.stringify(response.data.info.phoneCode))
          localStorage.setItem('phoneNo', JSON.stringify(response.data.info.phoneNo))
          localStorage.setItem('email', JSON.stringify(response.data.info.email))
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(acess)
    if (acess === "true") {
      console.log("sucesssss")
      this.props.history.push("/profileView")
    }
  }

  errorSubmit() {
    return <div className="alert alert-danger">Email address/Phone number already exist.</div>
  }

  render() {
    return (
      <div>
        <div className="col-lg-4" />
        <div className="card col-lg-4 box_shw2 border-0 px-3 rounded-2 mb-3 w_500 py-4 mx-auto mt-5">
          <span className="font-triluxo text-center">Triluxo</span>
          <div className="card-header bg-white text-secondary text-center">Welcome! Please update your profile to continue.</div>
          <p className="text-danger m-3">* All fields are required.</p>
          <div className="card-body">

            <form onSubmit={this.handleSubmit} encType="multipart/form-data" autoComplete="off">
              <div className="position-relative form-group">
                <input name="firstname" type="text" className="text-field form-control mb-3 bg-light border-0 py-3" placeholder="First Name *" value={this.state.firstname} onChange={this.handleChange} onBlur={this.handleBlur} required="required" />
              </div>
              <div className="position-relative form-group">
                <input name="lastname" type="text" className="text-field form-control mb-3 bg-light border-0 py-3" placeholder="Last Name *" value={this.state.lastname} onChange={this.handleChange} onBlur={this.handleBlur} required="required" />
              </div>
              <div className="position-relative form-group">
                <input name="email" value={this.state.email} className="text-field form-control mb-3 bg-light border-0 py-3" placeholder="Enter email address *" type="email" onChange={this.handleChange} onBlur={this.handleBlur} required="required" />
              </div>
              <div className="row position-relative form-group">
                <div className="col-1 mr-4 form-group">
                  <input style={{ width: "55px" }} name="phonecode" value={this.state.phonecode} className="text-field form-control mb-3 bg-light border-0 py-1" placeholder="code*" type="text"
                    onChange={this.handleChange} onBlur={this.handleBlur} maxLength="2" minLength="2" disabled required="required" />
                </div>
                <div className="col-9 form-group">
                  <input name="phoneno" type="number" className="text-field form-control mb-3 bg-light border-0 py-1" placeholder="Phone Number*"
                    onBlur={this.handleBlur} value={this.state.phoneno} onChange={this.handleChange} required="required" disabled type="tel" maxLength="10" />
                </div>
              </div>
              {this.state.success} {this.state.error}
              {!this.state.error ?
                (<p className="text-center mb-0"><input type="submit" className="btn btn-primary mt-3 px-5 py-3 f_12_14 border-0 d-inline-block" disabled={!this.state.email && !this.state.phonecode && !this.state.phoneno} value="Update and Continue" /></p>) : (null)}
            </form>

          </div>
        </div>
      </div>
    );
  }
}

export default AddProfile
